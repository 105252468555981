
import { defineComponent, ref } from 'vue';

declare var XLSX:any;
declare var xtos: any ;
declare var stox:any;

import  SerialConnection from "../../components/SerialConnection.vue";

import { Serial ,SerialUsbAutoConnectionDelegate} from "../../modules/hardware/serial";
import CameraConnection from "../../components/CameraConnection.vue";
import { Media } from "../../modules/hardware/media";
import {Geolocation , GeolocationCallback } from "../../modules/hardware/geolocation";
const detectCircleWorker = new Worker('../../modules/app/opencv/workers/detectcircle.js', { type: 'module' });

const piexif = require('piexifjs');

import { faFile } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';


// const Plugin  = require( "../../modules/app/plugin").Plugin;
// const cv  = require( "../../assets/opencv/opencv.js").cv;

const moment = require('moment');

// declare var cv: any;
// import XSpreadSheet  from "x-data-spreadsheet";

const cv = require('opencv.js');

const hov = 59.9484; 

const calibrationLongLengthMeterThreshold = 0.5 ;

var detectCircleSem = false;
// var circles = new cv.Mat();
var selectedCircleNo = 0 ;

const measurementTimerMs = 1000
const excludeBothLengthMs = 68;

//zzt
let detectCircleSetting :any  = {
    currentNum : 2 ,
    1: {
         dp: 1,
         minDist: 15,
         param1 : 51,
         param2: 21,
         minRadius : 25,
         maxRadius : 55
    },
    2: {
         dp: 1,
         minDist: 25,
         param1 : 50,
         param2: 27,
         minRadius : 70,
         maxRadius : 110
    },
    3: {
         dp: 1,
         minDist: 50,
         param1 : 50,
         param2: 27,
         minRadius : 95,
         maxRadius : 150
    } 
    
};

/*
let detectCircleSetting :any  = {
    currentNum : 2 ,
    1: {
         dp: 1,
         minDist: 15,
         param1 : 50,
         param2: 20,
         minRadius : 25,
         maxRadius : 55
    },
    2: {
         dp: 1,
         minDist: 25,
         param1 : 50,
         param2: 20,
         minRadius : 70,
         maxRadius : 110
    },
    3: {
         dp: 1,
         minDist: 50,
         param1 : 50,
         param2: 20,
         minRadius : 95,
         maxRadius : 150
    } 
    
};

*/

function downloadBase64Image(linkSource:string, fileName:string) {
     // const linkSource = `data:${contentType};base64,${base64Data}`;
     const downloadLink:any = document.createElement("a");
     downloadLink.href = linkSource;
     downloadLink.download = fileName;
     downloadLink.click();
}
    
function degToDmsRational(degFloat :any ) {
    var minFloat = degFloat % 1 * 60
    var secFloat = minFloat % 1 * 60
    var deg = Math.floor(degFloat)
    var min = Math.floor(minFloat)
    var sec = Math.round(secFloat * 100)

    deg = Math.abs(deg) * 1
    min = Math.abs(min) * 1
    sec = Math.abs(sec) * 1
  
    return [[deg, 1], [min, 1], [sec, 100]]
  }

function convertSensorDataToArr (tex:any){
        const arr = [];
        const texs =tex.split('\r\n');
        for ( var i=0 ; i < texs.length; i++ ){
                    const text = texs[i];
            
                    if(text.indexOf('E') !== -1) { continue; }    
               
                    const result = text.match(/D=(.*)m,/);
                 
                    if (result != null && result.length != 0 ) {  
        
                        arr.push( Number(result[1]) );
                    }
                  }
             return arr;
}

function refreshDetectCircleBtn () {
    const btn1:any = window.document.getElementById( "detectCircleSettingBtn1"); 
    btn1.className = "btn btn-dark";
   // btn1.classList.remove("btn-success"); btn1.classList.add("btn-dark");
              
    const btn2:any = window.document.getElementById( "detectCircleSettingBtn2"); 
    btn2.className = "btn btn-dark";
   // btn2.classList.remove("btn-success");  btn2.classList.add("btn-dark");
        
    const btn3:any = window.document.getElementById( "detectCircleSettingBtn3"); 
    btn3.className = "btn btn-dark";
    // btn3.classList.remove("btn-success"); btn3.classList.add("btn-dark");
}
       // cv.HoughCircles(gray,circles,cv.HOUGH_GRADIENT,1,20,  50, 30,40,80);
const white = new cv.Scalar(255, 225, 255,100);
const green = new cv.Scalar(100, 225, 205,100);

interface ICalibration {
  serial1Value:      number;
  serial2Value:      number;
  serial3Value:      number;
  add:      Function;
  ratio : Function;
}
 

export default {
  name: "BoreholeApp" ,
  data(): any {
          return {
            showApp :0 ,
            clearConnection:false,
            selectedSubCameraDevice : undefined,
            selectedMainCameraDevice : undefined,
            useSubCamera:false,
        cameraDeviceIds : [] ,
        denshiShoKokuban : {
            builder : "" ,
            projectName : "", 
            projectType : "" ,
            site :"",
            status : "" ,
            isNecessary : true 
        },
              hasCorrectionFile : false , 
              isMeasuring : false ,
              isMeasurementStopped : false,          
              recentlyAcquiredData : [ ] ,
              recentlyAcquiredDataMax : 20 ,
  
              acquiredData : [  ] ,
              directoryHandle : false , 
              enableDiameter : false , 
              isDebugMode : false , 
               debugModeCount : 0 ,
               calibrationA : -8.97142857,
               calibrationB:  1.202857143,
              // calibrationLongLengthMeter : 0.544 , 
                 calibrationLongLengthMeter : 0.750, 
               calibrationShortLengthMeter  : 0.088 ,
    
         testSettingVisble  : false,
         testDetectCircleSetting : {
         dp: 1,
         minDist: 20,
         param1 : 50,
         param2: 30,
         minRadius : 40,
         maxRadius : 80
         },
         
          geolocation : undefined , 
          gps : { lat : 0 , lon : 0},
          calibrationThresholdShortMeasurementsCentimeter : 65 ,
          calibrationCorrectionShortMeasurementsMillimetre : 9 ,
          
          calibration : {
            isLowerThanBothLengthChecked : true ,
            use : "add",
            serial1Value : 0,
            serial2Value : 0,
            serial3Value : 0
          } as any ,
       
        objectDetectingSetting : { area : { y : { top : 0.2, bottom: 0.25  } ,  x : { left : 0.2, right: 0.2  }  }},
         faFile :faFile,
        objectDetectingPoint : { start : { x:0 ,y:0 } , end : { x:0,y:0} } ,
        serialIntervalTaskFunc : undefined ,
        filename : null , 
        
       SpreadSheetKey : 0 , 
       avgLength : 0,
       fullLength : 0 ,
       diameter : 0 ,  
       DiameterCorrectionValue : 
       { detectStartPoint : 68  , coefficient :  1   },
         LengthCorrectionValue : {
            side: { sub: 0 } ,
            center : { thr : 830 , add : { above: 0 ,  below: 0 } }
        },
    
       selectCircleMode : false , 
       detectCircleIntervalID : undefined , 
       detectCircleIntervalClear : false ,
       
       currentCircle : { center: 0 , radius: 0 } , 
        circles : undefined , 
       serial1Value: 0 ,
       serial2Value: 0 ,
       serial3Value: 0 ,
       
       serial1Error : "",
       serial2Error : "",
       serial3Error : "",
       
         viewState:  {
            isCanvasShow :false,
            isExcelShow :true,
            isSettingShow :false,
            isExcelFileMenuShow : false,
            isExcelSelectCellMenuShow:false,
            selectCellMode : false ,
            fullLengthCellSelected : false ,
            diameterCellSelected: false ,
            gpsCellSelected : false
            }, 
         cameraInput :undefined,
         cameraOutput: undefined ,
         serial1 : undefined ,
         serial2 : undefined ,
         serial3 : undefined ,
         xlsxUpdated : false , 

         spreadsheet : null ,
         xSpreadSheetOption : { 
             showToolbar: false,
             showGrid: true,
                showContextmenu: false,
            },  
         xSpreadSheetData :[{}] ,
        
         selectedFullLengthCell: { 'row': -1 , 'col': -1 },
         selectedDiameterCell: { 'row': -1 , 'col': -1 },
         selectedGPSCell: { 'row': -1 , 'col': -1 },

         media1 : undefined ,
         mediaSubCamera : undefined,
         videoWidth:0,
         videoHeight:0 ,
         colWidth : 0 ,
         rowHeight : 0,
        
         canvas2dCtx : undefined,
         presetVideo: undefined 
      }

  },
  components:  { SerialConnection,CameraConnection } ,
  beforeUnmount(this: any ) {
    try{
      this.serial1.close();
    }catch(e){ console.error(e);}
     try{
      this.serial2.close();
    }catch(e){ console.error(e);}
     try{
      this.serial3.close();
    }catch(e){ console.error(e);}
   /*
 console.log("beforeUnmount");
        window.removeEventListener('message', this.outsideClickEvent);

    var spreadsheetArea = document.getElementById("spreadsheet-area");
    var xSpreadsheet = document.getElementById("x-spreadsheet");
    if (xSpreadsheet) {
      if(spreadsheetArea){
        spreadsheetArea.removeChild(xSpreadsheet);
      }
    }
*/

  },
async  mounted (this:any){
    console.log("mounted");
     if ( this.$route.query.show == "canvas" ){

      this.$nextTick( () => {
           setTimeout(async ()=>{
           await this.showArea("Canvas");   
           },1500);
        });   
      }
       
      if( (document.getElementById("x-spreadsheet") as any ).hasChildNodes() ) {
        console.log("spreadsheet destory");

        (document.getElementById("x-spreadsheet") as any).innerHTML = '';
      
      }
      console.log("spreadsheet created ");
      
      
      this.spreadsheet = 
        ( this.xSpreadSheetOption)?  (window as any).x_spreadsheet('#x-spreadsheet',this.xSpreadSheetOption)
       
       .loadData(this.xSpreadSheetData) :
        (window as any). x_spreadsheet( "#x-spreadsheet").loadData(  this.xSpreadSheetData );
       

      this.loadCalib();
      await this.initSettings();
       
  },
  beforeMount : function( this: any ){
         console.log("beforeMount");

  }
  ,updated(this:any){
    if ( this.xlsxUpdated ) {
        this.setXlsxFunc();
        this.xlsxUpdated = false ;
    }
  }
  , created(){} 
  ,methods:{
async startSubCamera(this:any){
  if (this.selectedSubCameraDevice){
    
    window.localStorage.setItem('boreholeSubCamera', JSON.stringify(this.selectedSubCameraDevice) );
    
    this.mediaSubCamera =new Media({
          audio: false,
          video : {  
              width: 1280, height: 800,
            // facingMode:  "user" ,
            facingMode: { exact: "environment" },
             deviceId  :  this.selectedSubCameraDevice.deviceId
             },
             secondary:{
              audio: false,
                video : {  
                width: 1280, height: 800,
                facingMode: "user",
                deviceId  :  this.selectedSubCameraDevice.deviceId
                } 
             }   
          } 
       ); 
       await this.mediaSubCamera?.open();
       this.$refs.videoSubCamera.srcObject = this.mediaSubCamera?.stream;
        this.$refs.videoSubCamera.addEventListener("loadedmetadata", () => {
               this.$refs.videoSubCamera.play();    
        });
        
  }
},
async initSettings(this: any){
          if ( this.$route.query.use == "subcamera" ){

          this.useSubCamera=true;      
      }
 
   const mediaDevices :any = await window.navigator.mediaDevices.enumerateDevices();

  const boreholeSubCamera:any = (():any => { try {  return JSON.parse(window.localStorage.getItem('boreholeSubCamera') as any ); } catch (e) { return {}; } })();
  const boreholeMainCamera:any = (():any => { try {  return JSON.parse(window.localStorage.getItem('boreholeMainCamera') as any ); } catch (e) { return {}; } })();
 
    for (let len = mediaDevices.length, i = 0; i < len; i++) {
      const item = mediaDevices[i];
      if (item.kind === "videoinput") {
        
        const deviceId = item.deviceId;
        const label = item.label;
   
      if (boreholeSubCamera && boreholeSubCamera .deviceId == deviceId ) {
    
        this.selectedSubCameraDevice = boreholeSubCamera;
      }
      if ( boreholeMainCamera && boreholeMainCamera .deviceId == deviceId ) {
        this.selectedMainCameraDevice = boreholeMainCamera;
      }

      this.cameraDeviceIds.push({ deviceId, label });
    }
  }
  
  if ( !this.selectedMainCameraDevice ){
   
      this.selectedMainCameraDevice = this.cameraDeviceIds[0];
      
    }
    // this.removeCalibData();
      
      window.document.addEventListener('click',this.outsideClickEvent );
      this.loadCalib();
      this.loadCurrentData();
      
      this.calibration. add = function( k:any,v:number) :number {
                const key:  keyof ICalibration = k;
               // console.log(key);
                const va = this[key] as number;

                    return  v + va  ;
            } ;
       this.calibration.ratio = function(k:any ,v:number) :number {
                       const key:  keyof ICalibration = k;
                       const va = this[key] as number;
                       return  v / va  ;
            } ;
      
        this.geolocation = new Geolocation (
            new class implements GeolocationCallback {
               public gps :any;
                constructor (gps:any){ this. gps = gps ; } 
                geolocationReceived = ( pos :any ):void => {
                     this.gps.lat = pos.coords.latitude;
                     this.gps.lon = pos.coords.longitude;
                }
                geolocationFailed = ( error :any ):void =>  {
                    console.error(error);
                    
                } 
            }(this.gps)  
        );
      
      this. xlsxUpdated = true;
      this.$emit('show-header');
      //9600/19200/38400/115200, default 38400
      
      if( !this.serial1){
        try{
        
        this.serial1 = new Serial( { baudRate:38400 , name : "serial1" , filter : { "usbProductId": 24577, "usbVendorId": 1027 } } );
        
        }catch (e){ console.error(e);}
      }
      if(!this.serial2){
        try{
        
        this.serial2 = new Serial( { baudRate:38400 , name : "serial2" , filter : { "usbProductId": 24577, "usbVendorId": 1027 } } );
     
        }catch (e){ console.error(e);}
      }
      if(!this.serial3){
      try{
      this.serial3 = new Serial( { baudRate:38400 , name : "serial3" , filter : { "usbProductId": 24577, "usbVendorId": 1027 } }  );
      }catch (e){ console.error(e);}
      }

      var con = new SerialUsbAutoConnectionDelegate(); 
       
      con.push(this.serial1);
      con.push(this.serial2);
      con.push(this.serial3);      
      con.enable();
      
     await con.connectIfConnectionExists();

if (!detectCircleWorker .onmessage){
detectCircleWorker .onmessage =(d :any ) => {
       
       if ( !d.data.error ){
              try{
                    if( !this.selectCircleMode){ //update the circle if selectCircleMode is false
                                
                        this.circles = d.data.data.circles;    
                        selectedCircleNo  = -1;   
                        this.selectCircle(false);
                    }
                }catch(e){ console.error(e); }
            }
            detectCircleSem = false;
        }
}


     this.startMainCamera();
     this.startSubCamera();


},

async uploadSubCameraImage(this:any,basefilename:string){

      // this.$refsを使ってビデオ要素にアクセス
      const videoElement:any = this.$refs.videoSubCamera;
      if (!videoElement) {
        console.error('ビデオ要素が見つかりません。');
        return;
      }

      // キャンバスを作成し、ビデオのフレームを描画
      const canvas:any = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const context:any = canvas.getContext('2d');
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      const base64img:any=context.canvas.toDataURL('image/jpeg', 1.0);
      this.uploadBase64Image(base64img, basefilename+"-subcamera.jpg" );
  },
async changeMainCamera(this:any){

 window.localStorage.setItem('boreholeMainCamera', JSON.stringify(this.selectedMainCameraDevice) );
 this.$router.push({ path: this.$route.path, query: { use : "subcamera", show: 'canvas' }});
 this.$router.go(0);
 

},
startMainCamera(this:any){

    // this.clearConnection=false;
 window.localStorage.setItem('boreholeMainCamera', JSON.stringify(this.selectedMainCameraDevice) );


navigator.mediaDevices.enumerateDevices()
.then(function(devices) { // 成功時
    devices.forEach(function(device) {
  // デバイスごとの処理
   // console.log(device);
    });
})
.catch(function(err) { // エラー発生時
 console.error('enumerateDevide ERROR:', err);
});

      this.canvas2dCtx = this.$refs.canvas.getContext("2d");
      
      
        this.media1= new Media({
          video : {   
                width: 1280, height: 800,
                facingMode: { exact: "environment" },
                deviceId: this.selectedMainCameraDevice.deviceId,
          
          }, 
          audio  :   false  ,
          secondary: {
             video :{
                width: 1280, height: 800,
                facingMode: "user",
                deviceId: this.selectedMainCameraDevice.deviceId   
              } ,audio :false
          }   
      }); 
      

      this.cameraInput = this.$refs.canvas;
      this.cameraOutput = this.$refs.canvasOutput;
      this.dispCanvas = this.$refs.dispCanvas;

      this.presetVideo = (video:any)=>{
              
               // video.style.display = "none";
                this.$refs.canvas.style.display = "none";
               try{
                
                if (this.detectCircleIntervalID ){
                
                   clearInterval( this.detectCircleIntervalID);
                }
               } catch (e){ console.error(e); }
          //zzt     aaa
        this.detectCircleIntervalID = setInterval( ()=>{
                try{
                if(this.detectCircleIntervalClear){
                 clearInterval( this.detectCircleIntervalID); 
                }
                 if( !this.selectCircleMode){
                         this.geolocation.getCurrentPosition();
             //        console.log(this.gps);
                        this.colWidth   = this. videoWidth  * 1/3;   //start pt
                        this.rowHeight   = this. videoHeight * 1/3;
                        this.detectCircle();   
                    }   
                }catch(e:any){

                  console.error(e);
                }
                 // this.$refs.canvas.style.display = "none";
            }  ,1000);
            
            /* this.detectCircleIntervalID = setInterval( ()=>{
                 if( !this.selectCircleMode){
                     console.log("detect mode on");
                         this.geolocation.getCurrentPosition();
                     console.log(this.gps);
                        this .colWidth   = this. videoWidth / 4;
                        this. rowHeight   = this. videoHeight / 4;
                        this.detectCircle();   
                    }   
            }  ,1000); */
             
             
             const dispCtx =   this.$refs.dispCanvas.getContext('2d');

             video.addEventListener('play', () => {
              
                        const func = ()=>{ 
                        
                           //zzt

const sx = 0; // video.videoWidth * 1/3 * 0.5; 
const sy = 0; //video.videoHeight * 1/3  * 0.5 ; 
const sWidth = video.videoWidth; 
const sHeight = video.videoHeight 

const h = video.videoHeight - this.rowHeight 
const w = this.colWidth 

 this.videoWidth = video.videoWidth;
 this.videoHeight = video.videoHeight; 

this.canvas2dCtx.drawImage(
    video, 
    sx, sy, sWidth, sHeight,
    sx, sy, sWidth, sHeight
);


 dispCtx.drawImage(
    video, 
    sx, sy, sWidth, sHeight,
    0, 0, sWidth/2, sHeight/2
);

if ( this.denshiShoKokuban.isNecessary ){

  
  // テキストのスタイルを設定
  dispCtx.fillStyle = '#3a5f47'; // 黒板風の色
  dispCtx.fillRect(0, 0, 160, 180);

  // フォントサイズを14pxに設定
  dispCtx.font = '12px Arial';
  dispCtx.fillStyle = 'white';

  // 各テキストをキャンバスの左上隅に配置
  dispCtx.fillText('1 施工者：'+this.denshiShoKokuban.builder, 10, 20);
  dispCtx.fillText('2 工事名：'+this.denshiShoKokuban.projectName, 10, 40);
  dispCtx.fillText('3 工種：'+this.denshiShoKokuban.projectType, 10, 60);
  dispCtx.fillText('4 施工箇所名：'+this.denshiShoKokuban.site, 10, 80);
  dispCtx.fillText('5 状況：'+this.denshiShoKokuban.status, 10, 100);
  dispCtx.fillText('6 削孔長：'+ this.ceilDemicalPoint ( this.fullLength  ,3 ) +'mm', 10, 120);
  dispCtx.fillText('7 削孔径：'+this.ceilDemicalPoint( this.diameter ,1)+'mm', 10, 140);


  this.canvas2dCtx.fillStyle = '#3a5f47'; // 黒板風の色
  this.canvas2dCtx.fillRect(0, 0, 160, 180);

  // フォントサイズを14pxに設定
  this.canvas2dCtx.font = '12px Arial';
  this.canvas2dCtx.fillStyle = 'white';

  // 各テキストをキャンバスの左上隅に配置
  this.canvas2dCtx.fillText('1 施工者：'+this.denshiShoKokuban.builder, 10, 20);
  this.canvas2dCtx.fillText('2 工事名：'+this.denshiShoKokuban.projectName, 10, 40);
  this.canvas2dCtx.fillText('3 工種：'+this.denshiShoKokuban.projectType, 10, 60);
  this.canvas2dCtx.fillText('4 施工箇所名：'+this.denshiShoKokuban.site, 10, 80);
  this.canvas2dCtx.fillText('5 状況：'+this.denshiShoKokuban.status, 10, 100);
  this.canvas2dCtx.fillText('6 削孔長：'+ this.ceilDemicalPoint ( this.fullLength  ,3 ) +'mm', 10, 120);
  this.canvas2dCtx.fillText('7 削孔径：'+this.ceilDemicalPoint( this.diameter ,1)+'mm', 10, 140);
 
  
}
                           /*  this.videoWidth = video.videoWidth;
                            this.videoHeight = video.videoHeight; 

                          this.canvas2dCtx.drawImage(  
                                video, 0,0 , 
                                video.videoWidth ,
                                video.videoHeight 
                            );  */
                            if (!this.detectCircleIntervalClear){
                               window.requestAnimationFrame(func);
                            }
                        }
                          if (!this.detectCircleIntervalClear){
                         window.requestAnimationFrame(func);
                          }
                   },true ); 
            };     
  },

      readCorrectionFile(this :any , e:any) {
         // console.error(e);
        
      const file = e.target.files[0]
      const reader:any = new FileReader()
     this.removeCalibData();
      reader.onload = ()=> {
         try{
           const data=JSON.parse(  atob( reader.result)  );
           
           if (  data.calibrationA ) { this.calibrationA = data.calibrationA ;}
           if ( data.calibrationB ) {  this.calibrationB = data.calibrationB;  }
            if ( data.calibrationLongLengthMeter ){ this.calibrationLongLengthMeter = data.calibrationLongLengthMeter; }
            if ( data.calibrationShortLengthMeter  ) { this.calibrationShortLengthMeter = data.calibrationShortLengthMeter; }
            if ( data.calibrationThresholdShortMeasurementsCentimeter  ) { this.calibrationThresholdShortMeasurementsCentimeter = data.calibrationThresholdShortMeasurementsCentimeter; }
            if (  data.calibrationCorrectionShortMeasurementsMillimetre ) { this.calibrationCorrectionShortMeasurementsMillimetre= data.calibrationCorrectionShortMeasurementsMillimetre;}
            if ( data.DiameterCorrectionValue ) {  this.DiameterCorrectionValue = data.DiameterCorrectionValue ;} 
            if ( data.calibration ) {  this.calibration = data.calibration; }    
            if ( data.testDetectCircleSetting ){  this.testDetectCircleSetting = data.testDetectCircleSetting ; }
      

            this.hasCorrectionFile = true;
            this.saveCalib();
          }catch (e){ 
            console.error(e);
          }
      }
      reader.readAsText( file )
    },
      makeCorrectionFile (this:any){
          this.saveCalib();
         const dataStr= ( window as any) . localStorage.getItem("CalibData") ;  
         
           let str = btoa(dataStr);
        let ary = str.split(''); 
        let blob = new Blob(ary,{type:"text/plan"}); 
        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob); 
        link.download = 'CorrectionFile.paletteiot'; 
        link.click(); 
        
      },
      removeCalibData (this:any){
          
            ( window as any) . localStorage.removeItem("CalibData");
      },
     // localStorage.clear();
      removeCurrentData (this:any){
          
           ( window as any) . localStorage.removeItem("RecentData");
      },
     loadCalib(this:any){
         try{
          const data=JSON.parse( ( window as any) . localStorage.getItem("CalibData") );
           if (  data.calibrationA ) { this.calibrationA = data.calibrationA ;}
           if ( data.calibrationB ) {  this.calibrationB = data.calibrationB;  }
            if ( data.calibrationLongLengthMeter ){ this.calibrationLongLengthMeter = data.calibrationLongLengthMeter; }
            if ( data.calibrationShortLengthMeter  ) { this.calibrationShortLengthMeter = data.calibrationShortLengthMeter; }
            if ( data.calibrationThresholdShortMeasurementsCentimeter  ) { this.calibrationThresholdShortMeasurementsCentimeter = data.calibrationThresholdShortMeasurementsCentimeter; }
            if (  data.calibrationCorrectionShortMeasurementsMillimetre ) { this.calibrationCorrectionShortMeasurementsMillimetre= data.calibrationCorrectionShortMeasurementsMillimetre;}
            if ( data.DiameterCorrectionValue ) {  this.DiameterCorrectionValue = data.DiameterCorrectionValue ;} 
         //  console.log(data);
            if ( data.calibration ) {  
             
             this.calibration.serial1Value = data.calibration.serial1Value;
              this.calibration.serial2Value = data.calibration.serial2Value;
              this.calibration.serial3Value = data.calibration.serial3Value; 

            }    
            if ( data.testDetectCircleSetting ){  this.testDetectCircleSetting = data.testDetectCircleSetting ; } 
            if (data.hasCorrectionFile) { this.hasCorrectionFile=data.hasCorrectionFile; }


        }catch (e){ console.error(e); }
     },
     saveCalib(this:any){
      
      ( window as any) . localStorage.setItem(
        "CalibData" ,
        JSON.stringify( {
            hasCorrectionFile : this.hasCorrectionFile,
            calibrationA : this.calibrationA ,
            calibrationB : this.calibrationB ,
            calibrationLongLengthMeter:  this.calibrationLongLengthMeter ,
            calibrationShortLengthMeter:  this.calibrationShortLengthMeter ,
            calibrationThresholdShortMeasurementsCentimeter: this.calibrationThresholdShortMeasurementsCentimeter ,
            calibrationCorrectionShortMeasurementsMillimetre: this.calibrationCorrectionShortMeasurementsMillimetre ,
            DiameterCorrectionValue: this.DiameterCorrectionValue  ,
            calibration : this.calibration , 
            testDetectCircleSetting : this.testDetectCircleSetting,
                 
        }));
        
     },
      loadCurrentData (this:any){
           try{      
           // this.removeCurrentData();
            const data=JSON.parse( ( window as any) . localStorage.getItem("RecentData") );
         
           
           if (data.filename) { this.filename = data.filename; }
          
            if (data.acquiredData) {   this.acquiredData  = data.acquiredData ; } 
            if (data.xSpreadSheetData) {

                 this.xSpreadSheetData  = data.xSpreadSheetData ;
                 this.spreadsheet.loadData(this.xSpreadSheetData);
                 this.SpreadSheetKey ++ ;
                    this.xlsxUpdated = true ;
                 }
             if (data.selectedFullLengthCell) { this.selectedFullLengthCell = data.selectedFullLengthCell ; }
            if (data.selectedDiameterCell) {  this.selectedDiameterCell = data.selectedDiameterCell ; }
            if (data.selectedGPSCell) {   this.selectedGPSCell = data.selectedGPSCell ; }
            
           
          }catch (e){ console.error(e); }
      },
     
      saveCurrentData(this:any){
      

      ( window as any) . localStorage.setItem(
        "RecentData" ,
        JSON.stringify( {
            
            filename : this.filename ,
            acquiredData : this.acquiredData ,  
            xSpreadSheetData :    this.spreadsheet.getData(),
            selectedFullLengthCell: this.selectedFullLengthCell ,
            selectedDiameterCell: this.selectedDiameterCell ,
            selectedGPSCell:  this.selectedGPSCell 
        }));
          
      },
  
      gpsLink (this:any){
            if (window.confirm("位置情報の確認 外部リンクを開きますか？ ")){
                 window.open("https://www.google.co.jp/maps/@"+this.gps.lat+","+this.gps.lon+",15z", '_blank');
          }
      },
      raitoValue(v:number, calibrationLongLengthMeter : number , calibrationShortLengthMeter :number ){
         if(0.5 < v){
             return v/ calibrationLongLengthMeter ;
         } 
         return v /  calibrationShortLengthMeter;
      }, 
       addValue(v:number, calibrationLongLengthMeter : number , calibrationShortLengthMeter :number ){
        if(0.5 < v){
             return calibrationLongLengthMeter -v;
         } 
         return  calibrationShortLengthMeter -v;  
      },
      async calibrationStart( this:any ){
          
          await this.measurement();
          
      },
      async calibrationAdd (this:any){
            this.calibration.use = "add";

            if( 0.5< this.serial1Value){
                  this.calibration.serial1Value = this.calibrationLongLengthMeter - this.serial1Value  ;      
            } else {
                  this.calibration.serial1Value =  this.calibrationShortLengthMeter - this.serial1Value  ;           
            }
            if( 0.5< this.serial2Value){
                  this.calibration.serial2Value = this.calibrationLongLengthMeter -this.serial2Value  ;      
            } else {
                  this.calibration.serial2Value = this.calibrationShortLengthMeter - this.serial2Value  ;           
            }
            
           if( 0.5< this.serial3Value){
                  this.calibration.serial3Value = this.calibrationLongLengthMeter - this.serial3Value  ;      
            } else {
                  this.calibration.serial3Value =  this.calibrationShortLengthMeter - this.serial3Value ;           
            }
           
            this.saveCalib();

           // await this.initSettings();
           alert( "設定しました。" );
      },
      calibrationRatio (this:any){
            this.calibration.use = "ratio";
        
            if( calibrationLongLengthMeterThreshold < this.serial1Value){
                  this.calibration.serial1Value = this.serial1Value /  this.calibrationLongLengthMeter   ;      
            } else {
                  this.calibration.serial1Value = this.serial1Value /  this.calibrationShortLengthMeter ;           
            }
            if( calibrationLongLengthMeterThreshold < this.serial2Value){
                  this.calibration.serial2Value = this.serial2Value / this.calibrationLongLengthMeter   ;      
            } else {
                  this.calibration.serial2Value = this.serial2Value /  this.calibrationShortLengthMeter ;           
            }
            
           if( calibrationLongLengthMeterThreshold < this.serial3Value){
                  this.calibration.serial3Value = this.serial3Value /  this.calibrationLongLengthMeter  ;      
            } else {
                  this.calibration.serial3Value = this.serial3Value /  this.calibrationShortLengthMeter ;           
            }
             alert( "設定しました。" );
            
      },
      ceilDemicalPoint (v:any , unit :number ){
      
       var s =String(v);
       var ss = s.split('.');
       if(1 < ss.length){
           
           var u:any = "";
           for ( var i=0; i< ss[1].length ; i ++ ){
               if( i < unit ){
                 u += ss[1][i];
               }
           } 
       }
       if(u===undefined) { u = "0"; }
       return ss[0] +"." + u;
      /* 
      桁溢れするので↑の実装..
       const u = (10**unit);
        var r = v * u;
       console.log(v);
                console.log(r);
      
         r = Math.ceil(v) 
        return r / u ;  
        */
      },
      exchangeToColName( num : number ){
                      return ["","A","B","C","D","E","F","G","H","I","J","K","L","M","N","O", "P","Q","R","S","T","U","V","W","X","Y","Z"][num];
        } ,
       async showArea(this : { viewState:any , cameraStart:any} ,area:string){
        
        this.viewState.isCanvasShow = false;
        this.viewState.isExcelShow = false;
        this.viewState.isSettingShow = false;
        switch( area ){
            case 'Canvas' :
               
               
                this.viewState.isCanvasShow = true;
               // await new Promise(s => setTimeout(s, 1500))
                try{
                await this.cameraStart();
                }catch (e){console.error(e);}
            break;
            case 'Excel':
                this.viewState.isExcelShow = true;              
            break;
            case 'Setting':
                 this.viewState.isSettingShow = true;
            break;
        }  
        
    },
    async cameraStart (this:any){
          try{  
            await this.$refs.cameraConnection.open();  } catch (e){
               console.error(e);}
     
            this.viewState.isCanvasShow = false;
            this.viewState.isCanvasShow = true;
             //  this.showArea("Excel");
             //this.showArea("Canvas");  
    },
    showExcelMenu (this:{ viewState:any},state:string){
        
        switch(state){
            case 'File' :
             this.viewState.isExcelSelectCellMenuShow = false;
             this.viewState.isExcelFileMenuShow = !this.viewState.isExcelFileMenuShow ;
            break;
            case 'CellSelection': 
            this.viewState.isExcelFileMenuShow = false ;
            this.viewState.isExcelSelectCellMenuShow = !this.viewState.isExcelSelectCellMenuShow;
            break;
      }
    },
        onDebugMode(this:any){
        this.debugModeCount ++  ;
        if ( 29 < this.debugModeCount  ){
            this.isDebugMode=true;
        }
    },
    selectCircle(this:any, modeOn = true ){
        this.selectCircleMode = modeOn;
        if ( !this.circles  ){ return ; }
        if ( !this.circles.cols  ){ return ; }
         const dispCircleCtx =   this.$refs.dispCircleCanvas.getContext('2d');

        let src = cv.imread(   this.cameraInput  );// this.cameraInput);  
        let dst = new cv.Mat.zeros(src.rows, src.cols, cv.CV_8UC4);
        // dst.setTo(new cv.Scalar(0,10,0,1));

     for (let i = 0; i < this.circles.cols; ++i) {   
             let x = this.circles.data32F[i * 3] + this.colWidth;
            let y = this.circles.data32F[i * 3 + 1] + this.rowHeight;
            let radius = this.circles.data32F[i * 3 + 2];
            let center = new cv.Point(x, y);
            cv.circle(dst, center, radius, white);
        }
        selectedCircleNo = ( selectedCircleNo >= this.circles.cols-1  ) ? 0 : selectedCircleNo + 1 ;
       
        this.currentCircle.radius = this.circles.data32F[selectedCircleNo * 3 + 2];
        this.currentCircle.center = new cv.Point(
            this.circles.data32F[selectedCircleNo * 3] + this.colWidth , // x
            this.circles.data32F[selectedCircleNo * 3 + 1]  + + this.rowHeight // y
        );
        
        
        cv.circle(dst,  this.currentCircle.center,  this.currentCircle.radius, green,2);   
        cv.imshow( this.cameraOutput, dst);

     const cw = this.cameraOutput.width/2;
     const ch = this.cameraOutput.height/2;
     dispCircleCtx.clearRect(0, 0, cw, ch);
      dispCircleCtx.drawImage(
         this.$refs.canvasOutput, 
        0, 0, this.cameraOutput.width, this.cameraOutput.height,
        0, 0, cw, ch );

        
        src.delete();
        dst.delete(); 
        this.calculateDiameter();
        
    
        
    },
    selectDetectCircle(this:any,num:number ){
    
     //   this.selectCircleMode = true;
        detectCircleSetting.currentNum = num ;
        refreshDetectCircleBtn();
        const btn:any = window.document.getElementById( "detectCircleSettingBtn" + String( num ) );
        btn.classList.remove("btn-dark");
        btn.classList.add("btn-success");
         this.detectCircle();
        this.calculateDiameter();
       
    },
     onSelectCircleMode ( this:any  ){
         try{
                        this.diameter = 0 ;
                     this.cameraOutput .getContext("2d").clearRect(0, 0, this.cameraOutput.width,  this.cameraOutput.height);
                }catch (e){
                    console.error(e);
                }
         this.selectCircleMode = !this.selectCircleMode;
        
    },
    
    detectCircle(this:any){
               
        
        try{
                 if( !detectCircleSem ) { //no other circle detecton in the process, //Sends a new request to the worker for circle detection
            detectCircleSem = true;
            const para = JSON.parse(JSON.stringify(this.testDetectCircleSetting)) ;
            
        //    console.error("**************************Testing");
        //    console.error("**************************Testing", this.colWidth, " this.rowHeight ", this.rowHeight);
 //---------------------^------------Original code end aaa
            //visuslize image_zzt
            const imgData = this.canvas2dCtx.getImageData(0, 0, this.cameraInput.width, this.cameraInput.height);
            const h = this.videoHeight - this.rowHeight 
            const w = this.colWidth 
              // const imgData = this.canvas2dCtx.getImageData(0, 0, width, height);
              this.canvas2dCtx.putImageData(imgData, 0, 0); 
             detectCircleWorker.postMessage({
                type : "detect",
                imgData : imgData,
                crop : { rect :   new cv.Rect (  this.colWidth,  this.rowHeight , w , h ) }, 
                setting :
                    (this.isDebugMode) ?
                     { 
                    dp: Number(para.dp),
                    minDist: Number(para.minDist),
                    param1 : Number(para.param1),
                    param2: Number(para.param2),
                    minRadius : Number(para.minRadius),
                    maxRadius : Number(para.maxRadius)
                } : detectCircleSetting[ detectCircleSetting.currentNum ]
       });   
                 //crop : { rect :   new cv.Rect (  this.colWidth,  this.rowHeight   , this.colWidth*1.5 , this.rowHeight * 3   ) },   
                 //crop : { rect :   new cv.Rect (  this.colWidth,  this.rowHeight   , this.colWidth*2 , this.rowHeight * 3   ) },      //org
       }
        } catch (e:any){
           detectCircleSem = false;
           console.error(e);
       }
    },
      calculateDiameter(  this: any ){

      if(this.avgLength){

     const LeaserStartingPoint =  this.DiameterCorrectionValue.detectStartPoint ;
    const destAngle = ( ( (this.currentCircle.radius  ) /  560 /*554.25*/ /*this.cameraOutput.width*/ ) * (hov) ) ;
    if(destAngle <0){ return ; }
    
    const rad = ( ( destAngle) * Math.PI / 180 ) ;
     
     const d =   (((  ( this.avgLength - this. calibrationA ) /this. calibrationB  ) + LeaserStartingPoint ) * Math.tan ( rad  ))*2 ;
     if( d < 0   ){ return ;}

       this.diameter =  d*1.05; // (d* this.DiameterCorrectionValue.coefficient) * / diameterCoefficient;
     }
    //alert(rad);
},
xlsxFunc  (this:any , cell: any, row: number, col: number){
          
          

          if ( this.viewState.selectCellMode ){
              if( !this.viewState.fullLengthCellSelected){

                  
                  if (window.confirm("削孔長のセルを設定しますか？")){
                      this.selectedFullLengthCell.row = row;
                      this.selectedFullLengthCell.col = col;
                     this.viewState.fullLengthCellSelected = true;
                     return;
                  }
              } else
               if(! this.viewState.diameterCellSelected){
                   
                                   if (this.selectedFullLengthCell.row == row &&
                    this.selectedFullLengthCell.col ==col ){
                  return;
                 }

                  if (window.confirm("削孔径のセルを設定しますか？")){
                      this.selectedDiameterCell.row = row;
                      this.selectedDiameterCell.col = col;
                      this.viewState.diameterCellSelected = true;
                       return;
                   }
              }else 
               if(! this.viewState.gpsCellSelected){

                                if (this.selectedFullLengthCell.row == row &&
                    this.selectedFullLengthCell.col ==col ){
                  return;
                 }
                 
                if (this.selectedDiameterCell.row == row &&
                  this.selectedDiameterCell.col ==col ){
                  return;
                }

                  if (window.confirm("位置情報のセルを設定しますか？")){
                      this.selectedGPSCell.row = row;
                      this.selectedGPSCell.col = col;
                      this.viewState.gpsCellSelected = true;
                      this.viewState.selectCellMode = false;
                       return;
                   }     
                  
              }
            }
},
  setXlsxFunc (this:any){
      
            this.spreadsheet.on('cell-selected', this.xlsxFunc );   
  },
  exportXlsx(this : any ){
           const filename = window.prompt( "ファイル名を入力して下さい。");
           if(filename == "" || filename == null ){ return ; }
           
           
           const new_wb = xtos( this.spreadsheet.getData());

          XLSX.writeFile(new_wb, filename+".xlsx");
       },
    async saveXlsx(this :any){
           if( this.filename == "" || this.filename == null ){ 
                 this.$emit('show-flash',{"message":"ファイル名がありません。","type": "warning"});          
                return ;
            }
           
           const new_wb = xtos( this.spreadsheet.getData());
              
            try{
               
            var wbout = XLSX.write(new_wb, {
                bookType:'xlsx',
                bookSST:true,
                type:'base64'
            });
        
         const r = await axios.post (process.env.VUE_APP_API_URL +"Base64DataUploadHttpTrigger/"+ process.env.VUE_APP_API_CODE ,
          {data:wbout,filename: moment().format('YYYY/MM/DD/') +  this.filename  + ".xlsx"  });
   
          if(r.data.error==0){
              
               XLSX.writeFile(new_wb, this.filename+".xlsx");
                  this.$emit('show-flash',{"message":"ファイルを保存しました。","type": "success"});        
          } else {
               this.$emit('show-flash',{"message":"クラウドへのファイル保存に失敗しました。ネットワークを確認してください。","type": "danger"});  
              
               XLSX.writeFile(new_wb, this.filename+".xlsx");
        
                      
          }
          }catch(e :any ){
               this.$emit('show-flash',{"message":"クラウドへのファイルの保存に失敗しました。ネットワークを確認してください。","type": "danger"});      
              
                  XLSX.writeFile(new_wb, this.filename+".xlsx");      
          }
          
       },
        
  importXlsx(this :any,e:any){
           
      if (e.target instanceof HTMLInputElement) { 
        const file =  e.target.files[0];
        const reader : any = new FileReader();
        reader.onload = () => {
            
          const wb = XLSX.read(reader.result, { type: "binary" })
          this.xSpreadSheetData = stox(wb);

          this.SpreadSheetKey ++ ;
          this.xlsxUpdated = true ;
         // console.log(this.xSpreadSheetData);
          this.spreadsheet.loadData(this.xSpreadSheetData);
        }
         reader.readAsBinaryString(file);
      }  
  
       },   
        outsideClickEvent (this:{ viewState:any },e:any)  { 
            // alert(e.target.className);
              if(  e.target.className.indexOf( 'show-excel' ) ===  -1 ){  this. viewState. isExcelFileMenuShow = false;  }
          }
          ,selectCellMode( this:any ){
                 alert("データを記入するセルを選択してください。");

                // this.$emit('show-flash',{"message":"データを記入するセルを選択してください。","type": "success"});   
                this.showArea('Excel');
                this. viewState. isExcelFileMenuShow = false; 
                this.viewState.selectCellMode = true;   
                this.viewState.fullLengthCellSelected = false;
                this.viewState.diameterCellSelected = false;
                this.viewState.gpsCellSelected = false;

          
       } ,
   async  writeFile(this:any, fileHandle:any, contents:any) {
  // writable作成
  const writable = await fileHandle.createWritable();

  // コンテンツを書き込む
  await writable.write(contents);
  // ファイル閉じる
  await writable.close();
    },
           
        async startMeasurement( this:any ){
           if (  !this.filename || this.filename == ""){
                        
           this.filename = window.prompt( "ファイル名を入力して下さい。");
           if ( !this.filename || this.filename == "" ){
               
             this.$emit('show-flash',{"message":"ファイル名をご入力してください。","type": "warning"});      
               return ; 
           }
         }
        if ( this.isMeasuring) {
            
         if ( window.confirm("本当に計測を終了しますか？")){
             
              this.saveXlsx();
              this.removeCurrentData();
             
         }
        
        this.isMeasuring=false ;
         return;
        }
        
         
  //          /*   this.filehandle = await ( window as any ).showSaveFilePicker({ types: [{  description: "Text Files", accept: { "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"[".xlsx"],},},] }); await this.writeFile(this.fileHandle, ""); */
         
        /*   if( 
                (  this.selectedDiameterCell.row === -1 && this.selectedDiameterCell.col === -1 ) ||
                this.selectedFullLengthCell.row === -1, this.selectedFullLengthCell.col === -1 || 
                this.selectedGPSCell.row === -1, this.selectedGPSCell.col === -1
            ){
              */
                this.selectCellMode();

            //    return ;
          //  }
             this.isMeasuring=true ;
    
            await this.measurement();
                      
    //        this.showArea('Canvas');
         
        },
        async uploadBase64Image (this:any,base64img:any,filename:string){
        
        try{
           const base64string = base64img.split('base64,')[1];     
           const  r  = await axios.post (process.env.VUE_APP_API_URL +"Base64DataUploadHttpTrigger/"+ process.env.VUE_APP_API_CODE ,
          {data: base64string ,filename:  filename }
            );
        
          if(r.data.error==0){
              
                  this.$emit('show-flash',{"message":"写真をアップロードしました。","type": "success"});
                  downloadBase64Image(base64img,filename);
          } else {
               
               this.$emit('show-flash',{"message":"写真のアップロードに失敗しました。ネットワークを確認してください。","type": "danger"});          
               downloadBase64Image(base64img,filename);
          }

          } catch(e :any ){
               
               this.$emit('show-flash',{"message":"写真をアップロードに失敗しました。ネットワークを確認してください。","type": "danger"});      
               
                  downloadBase64Image(base64img,filename);
          }
        },
        async measurement ( this : any ) {
          
            try{ if(  !( await this.serial1?.getInfoAsync()) ){ await this.serial1?.connect(); } } catch(er){ console.error(er); }
            try{ if( !( await this.serial2?.getInfoAsync()) ){ await this.serial2?.connect();} } catch(er){ console.error(er); }
            try{ if( !( await this.serial3?.getInfoAsync()) ){ await this.serial3?.connect();} } catch(er){ console.error(er); }
        
            try{
                                
                this.serialIntervalTaskFunc= setInterval( async ()=>  {
                       
                    if( this.isMeasurementStopped ){ return ; }
                    this.serial1?.setReaderLock();
                       
                    await this.serial1?.sendString("iSM\n");
                    var text1:any =  await this.serial1?.readString();  
                
                    const arr1=convertSensorDataToArr( text1);
                    
                    for(let i = 0 ;  i < arr1.length ; i++){     
                         this.serial1Value = this.calibration[ this.calibration.use ] ( "serial1Value",  arr1[i] ); 
                    }
               
                    await this.serial1?.releaseReaderLock();
          
                    this.serial2?.setReaderLock();
                        
                    await this.serial2?.sendString("iSM\n");
                    var text2:any =  await this.serial2?.readString();  
                        
                    const arr2 =convertSensorDataToArr( text2);
                    
                    for(let i = 0 ;  i < arr2.length ; i++){     
                         this.serial2Value = this.calibration[ this.calibration.use ] ( "serial2Value",  arr2[i] ); 
                    }
               
                    await this.serial2?.releaseReaderLock();
           
                    this.serial3?.setReaderLock();
                        
                    await this.serial3?.sendString("iSM\n");
                    var text3:any =  await this.serial3?.readString();  
                        
                    const arr3 =convertSensorDataToArr( text3);
                    
                    for(let i = 0 ;  i < arr3.length ; i++){     
                         this.serial3Value = this.calibration[ this.calibration.use ] ( "serial3Value",  arr3[i] ); 
                    }
               
                    await this.serial3?.releaseReaderLock();
    
                    const values = [  this.serial1Value * 1000 ,this.serial2Value*1000, this.serial3Value*1000 ].sort(
                        function (a, b) { return a - b }
                    );
           
                    const v1 =  values[0] ;
                    const v2 =  values[0] ;
                    var v3 = values[2];
                    
                    if (this.calibration. isLowerThanBothLengthChecked ){
                  
                        if (  excludeBothLengthMs  >=  v1 ){
                            this.avgLength = v1;
                        }   
                        if (  excludeBothLengthMs  >=  v2 ){
                            this.avgLength = v2;
                        } else{
                             this.avgLength = ( ( v1 + v2)  /2 );
                        }  
                    } else {
                        
                        this.avgLength = ( ( v1 + v2)  /2 );
                    }
             
                    if(
                        v3 <= this.LengthCorrectionValue.center.thr 
                    ){
                
                        v3 = v3 +this.LengthCorrectionValue.center.add.above;
                    }else{
                
                        v3 = v3+ this.LengthCorrectionValue.center.add.below;
                    }
                     var fullLength = v3 - this.avgLength;
                    
                     if (this.calibrationCorrectionShortMeasurementsMillimetre != 0 &&
                         this.calibrationThresholdShortMeasurementsCentimeter >= fullLength ){
                          fullLength = fullLength -  this.calibrationCorrectionShortMeasurementsMillimetre /10 ;
                        }
                     this.fullLength = fullLength;
                }  , measurementTimerMs );
                
               
              }catch(e){
                
                this.serial1?.releaseReaderLock();
                this.serial2?.releaseReaderLock();
                this.serial3?.releaseReaderLock();
              }
        },
        async stop ( this : any , param  :any ) {
          if( !this.isMeasuring){return;}
          if (! this.filename ){
                       this.$emit('show-flash',{"message":"計測を開始してください。","type": "warning"});          
              return;
          } 
        
          this.isMeasurementStopped =true;
          this.$refs.cameraSound.play();
        
           const basefilename =  moment().format('YYYY/MM/DD/')  +  this.filename + "-" + moment().format('YYYY-MM-DDTHH:mm:ss');
           const filename =  basefilename + ".jpg";
           if(this.useSubCamera){

              this.uploadSubCameraImage(basefilename);
           }
          this.enableDiameter = param.diameter ;
        
             const context:any = document.createElement('canvas').getContext('2d')
             //zzt
             if(!this.enableDiameter){
                 
                this.cameraOutput .getContext("2d").clearRect(0, 0, this.cameraOutput.width, this.cameraOutput.height);
             }
             
           /* if(!this.enableDiameter){
                this.cameraOutput .getContext("2d").clearRect(0, 0, this.cameraOutput.width, this.cameraOutput.height);
             }
             */
             
             var lastdata :any = {};
             
    //try{
      
            const img1 = new Image();
            await new Promise((resolve, reject) => {
                img1.onload = () => resolve(img1);
                img1.onerror = (e) => reject(e);
                img1.src =  this.$refs.canvasOutput.toDataURL();
            })
                        
            const img2 = new Image();    
            await new Promise((resolve, reject) => {
                img2.onload = () => resolve(img2);
                img2.onerror = (e) => reject(e);
                img2.src =  this.$refs.canvas.toDataURL();
            })
            
            context.canvas.width = this.$refs.canvas.width;
            context.canvas.height = this.$refs.canvas.height;
            
            
            context.drawImage(img2,0,0)
            context.drawImage(img1,0,0)
            
            var base64img = context.canvas.toDataURL('image/jpeg', 1.0);
                
            if ( this.gps.lat && this.gps.lon ){
                const exifObj = piexif.load(base64img);
        
                exifObj.GPS[piexif.GPSIFD.GPSLatitude] =  degToDmsRational( this.gps.lat );
                exifObj.GPS[piexif.GPSIFD.GPSLatitudeRef] =  "N";
                exifObj.GPS[piexif.GPSIFD.GPSLongitude] =  degToDmsRational( this.gps.lon );
                exifObj.GPS[piexif.GPSIFD.GPSLongitudeRef] =  "W";

                const exifbytes = piexif.dump(exifObj);
                base64img = piexif.insert(exifbytes, base64img);
            } 
            
            const base64string = base64img.split('base64,')[1];       
            
            
            await this.uploadBase64Image (base64img,filename);
        //   const  r  = await axios.post (process.env.VUE_APP_API_URL +"Base64DataUploadHttpTrigger/"+ process.env.VUE_APP_API_CODE ,
       //   {data: base64string ,filename:  filename }
       //     );
        /*
          if(r.data.error==0){
              
                  this.$emit('show-flash',{"message":"写真をアップロードしました。","type": "success"});
                  downloadBase64Image(base64img,filename);
          } else {
               
               this.$emit('show-flash',{"message":"写真のアップロードに失敗しました。ネットワークを確認してください。","type": "danger"});          
               downloadBase64Image(base64img,filename);
          }

          }catch(e :any ){
               
               this.$emit('show-flash',{"message":"写真をアップロードに失敗しました。ネットワークを確認してください。","type": "danger"});      
               
                  downloadBase64Image(base64img,filename);
          }
      */
      
           lastdata.img = {  name : filename , base64 :  base64img };
            
            const sheet = this.spreadsheet;
            
             sheet.cellText( 
                this.selectedFullLengthCell.row , 
                this.selectedFullLengthCell.col ,
                this.fullLength  // this.ceilDemicalPoint ( this.fullLength * 0.001 ,3 )
              ).reRender();
            
            if(this.enableDiameter){
                
              sheet.cellText( 
                  this.selectedDiameterCell.row , 
                  this.selectedDiameterCell.col ,
                  this.diameter  //  this.ceilDemicalPoint ( this.diameter * 0.1 ,1 )
               ).reRender();      
            }
            sheet.cellText( this.selectedGPSCell.row , this.selectedGPSCell.col ,
                 this.gps.lat 
            ).reRender();    
             sheet.cellText( this.selectedGPSCell.row , this.selectedGPSCell.col+1 ,
               this.gps.lon
            ).reRender();      
            
            this.selectedFullLengthCell.row = this.selectedFullLengthCell.row + 1 ;
            this.selectedDiameterCell.row  = this.selectedDiameterCell.row  + 1 ;
            this.selectedGPSCell.row  = this.selectedGPSCell.row  + 1 ;   
            
              lastdata.fullLength = { col :  this.selectedFullLengthCell.col , row : this.selectedFullLengthCell.row ,  data : this.fullLength  } as any;
              lastdata.diameter = {  col :  this.selectedDiameterCell.col ,  row :   this.selectedDiameterCell.row ,  data : this.diameter   } as any;
              
              lastdata.gps = {} as any ;
              lastdata.gps.lat  = {  col :  this.selectedGPSCell.col ,  row :   this.selectedGPSCell.row ,  data :  this.gps.lat    } as any;
              lastdata.gps.lat  = {  col :  this.selectedGPSCell.col ,  row :   this.selectedGPSCell.row ,  data :  this.gps.lon    } as any;
              
              if (this. recentlyAcquiredDataMax <= this.recentlyAcquiredData.length-1 ){
                  this.recentlyAcquiredData.splice(0, 1) ;
              }   
              
             this.recentlyAcquiredData.push(lastdata);
              
            this.acquiredData.push(lastdata);
              
          //   await this.saveXlsx();   
             this.isMeasurementStopped =false;
             // this.showArea('Excel');
             this.saveCurrentData();
        }    
       
    }
    
}

