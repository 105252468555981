
import { defineComponent } from "vue";
import { Media } from "../modules/hardware/media";

export default defineComponent({
  name: "CameraConnection" ,
  props:{
      media :Media , 
    videoWidth:Number,
    videoHeight:Number, 
    presetVideo:Object,
    canvas2dCtx:Object
  },
  mounted :async function(this:any){
      this.$refs.video.addEventListener("loadedmetadata", () => {
             
               this.$refs.video.play();    
             });
  },
  methods:{
    getVideo (this:any)
{ return this.$refs.video ; },     
 async open( this : {
           media?:Media ,
           $refs?:any,
           canvas2dCtx:any , 
           presetVideo:any
            } ){

            
           try{ 
            //this.$refs.video.srcObject=null;
            await this.media?.open();
           
            this.$refs.video.srcObject = this.media?.stream; 
             if(this.presetVideo){
             
                    this.presetVideo(this.$refs.video);
            }
           // this.$refs.video.play();
           }catch (e:any){

            console.error(e);
           }
         
     },  // stop( this : { $refs?:any} ){   this.$refs.video.stop(); }
    }
});

